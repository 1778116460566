/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/bind.js
 * 我的
 */
export default [{
  path: '/bind',
  name: 'bind',
  meta: {
    index: 1,
    title: "绑定信息"
  },
  component: () => import('@/views/bind/index.vue')
}]
