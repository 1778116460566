/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/meal.js
 * 营养餐
 */
export default [{
  path: '/meal',
  name: 'meal',
  meta: {
    index: 1,
    title: "营养餐"
  },
  component: () => import('@/views/meal/index.vue')
}]
