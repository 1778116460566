/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/addressMgr.js
 * 地址管理
 */
export default [
  {
    path: '/order-mgr-login',
    name: 'orderMgrLogin',
    meta: {
      index: 1,
      title: "订单管理系统登录"
    },
    component: () => import('@/views/orderMgr/login.vue')
  },
  {
    path: '/order-mgr-home',
    name: 'orderMgrHome',
    meta: {
      index: 1,
      title: "订单管理系统主页"
    },
    component: () => import('@/views/orderMgr/home.vue')
  },
  {
    path: '/order-mgr-info',
    name: 'orderMgrInfo',
    meta: {
      index: 1,
      title: "订单详情"
    },
    component: () => import('@/views/orderMgr/info.vue')
  },
  {
    path: '/order-mgr-list',
    name: 'orderMgrList',
    meta: {
      index: 1,
      title: "订单列表"
    },
    component: () => import('@/views/orderMgr/list.vue')
}]
