/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/doOrder.js
 * 订单信息
 */
export default [{
  path: '/doOrder',
  name: 'doOrder',
  meta: {
    index: 1,
    title: "订单信息"
  },
  component: () => import('@/views/doOrder/index.vue')
},
{
  path: '/doMealOrder',
  name: 'doMealOrder',
  meta: {
    index: 1,
    title: "订单信息"
  },
  component: () => import('@/views/doOrder/meal.vue')
}]
