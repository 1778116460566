
export default [{
  path: '/complaint',
  name: 'complaint',
  meta: {
    title: "问题反馈"
  },
  component: () => import('@/views/complaint/index.vue')
},
{
  path: '/complete',
  name: 'complete',
  meta: {
    index: 2,
    title: "反馈完成"
  },
  component: () => import('@/views/complaint/complete.vue')
}]
