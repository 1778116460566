/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/bill.js
 * 我的订单
 */
export default [{
  path: '/bill',
  name: 'bill',
  meta: {
    index: 1,
    title: "我的订单"
  },
  component: () => import('@/views/bill/index.vue')
},
{
  path: '/bill-info',
  name: 'bill-info',
  meta: {
    index: 2,
    title: "订单详情"
  },
  component: () => import('@/views/bill/detail.vue')
}]
