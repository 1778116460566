/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/makeOrder.js
 * 订餐
 */
export default [{
  path: '/makeOrder',
  name: 'makeOrder',
  meta: {
    index: 1,
    title: "食堂订餐"
  },
  component: () => import('@/views/makeOrder/index.vue')
},
{
  path: '/makePrefabOrder',
  name: 'makePrefabOrder',
  meta: {
    index: 1,
    title: "预制菜订餐"
  },
  component: () => import('@/views/makeOrder/prefab.vue')
},
{
  path: '/makeMealOrder',
  name: 'makeMealOrder',
  meta: {
    index: 1,
    title: "膳食套餐"
  },
  component: () => import('@/views/makeOrder/meal.vue')
}]
