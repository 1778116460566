/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/addressMgr.js
 * 地址管理
 */
export default [{
  path: '/addressMgr',
  name: 'addressMgr',
  meta: {
    index: 1,
    title: "地址管理"
  },
  component: () => import('@/views/addressMgr/index.vue')
},
{
  path: '/editAddress',
  name: 'editAddress',
  meta: {
    index: 1,
    title: "编辑地址"
  },
  component: () => import('@/views/addressMgr/edit.vue')
}]
