// import { setStore } from "../../utils/util";
const state = {
  setScrollY: 120
}
const getters = {
  getScrollY(state) {
    return state.setScrollY
  }
}
const mutations = {
  setScrollYCommit(state, data){
    state.setScrollY = data
  }
}
const actions = {
  setScrollYAction({commit}, data){
    commit('setScrollYCommit',data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
