/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/homepage.js
 * 主页
 */
export default [{
  path: '/homepage',
  name: 'homepage',
  meta: {
    index: 2,
    title: "首页"
  },
  component: () => import('@/views/homepage/index.vue')
}]
