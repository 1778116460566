/*
 * @version: v 1.0.0
 * @Author: cenhan
 * @Date: 2022-11-22 17:05:34
 * @FilePath: /src/router/modules/nutritionInformation.js
 * 营养资讯
 */
export default [{
  path: '/nutritionInformation',
  name: 'nutritionInformation',
  meta: {
    index: 1,
    title: "营养资讯"
  },
  component: () => import('@/views/nutritionInformation/index.vue')
}]
